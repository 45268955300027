import React, { useEffect, useState } from 'react';
import './product.css';
import Card from '../../components/card';
import Addtocart from '../../components/addtocart';
import Accordion from '../../components/accordion';
import Carousel from './mobileSlider';
import { useParams } from 'react-router';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { Link } from 'react-router-dom';
import axiosClient from './axios_client';

function Product() {
  const { product_id } = useParams();
  const { cartItems, updatecartItems } = useShoppingCart();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [quantity, setQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState({
    images: [],
    title: '',
    description: '',
    price: '',
    category: '',
    sizes: [],
    colors: [],
    similarProducts: [],
  });
  const [selectedProductSize, setSelectedProductSize] = useState('');
  const [selectedProductColor, setSelectedProductColor] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial state

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProductFromServer = async () => {
      try {
        const response = await axiosClient.get(`/products/${product_id}`);
        setProductDetails({
          images: response.data.productImages,
          title: response.data.product.title,
          description: response.data.product.description,
          price: response.data.product.price,
          category: response.data.category,
          sizes: response.data.product.sizes,
          colors: response.data.product.colors,
          similarProducts: response.data.similarProducts,
        });
        setSelectedProductSize(response.data.product.sizes[0]);
        setSelectedProductColor(response.data.product.colors[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProductFromServer();
    console.log(productDetails)

  }, [product_id]);

  const handleQuantityChange = (amount) => {
    setQuantity(Math.max(quantity + amount, 1));
  };

  const handleColorChange = (index, color) => {
    const selectedColor = productDetails.colors[index];
    setSelectedProductColor(selectedColor);
  };

  const handleSizeChange = (e) => {
    setSelectedProductSize(e.target.value);
  };

  // const handleAddedProduct = () => {
  //   if (!selectedProductSize || !selectedProductColor) {
  //     setError('Please select a size and color');
  //     return;
  //   }
  //   setError(null);

  //   function generateRandomItemId() {
  //     return Math.floor(Math.random() * 100000);
  //   }

  //   const product = {
  //     item_id: generateRandomItemId(),
  //     title: productDetails.title,
  //     description: productDetails.description,
  //     price: +productDetails.price,
  //     category_id: productDetails.category.category_id,
  //     size: selectedProductSize,
  //     color: selectedProductColor,
  //     image: productDetails.images[0],
  //     quantity: quantity,
  //     totalPrice: productDetails.price * quantity,
  //     product_id: +product_id,
  //   };

  //   if (cartItems.length <= 1) {
  //     updatecartItems([product]);
  //   } else {
  //     updatecartItems([...cartItems, product]);
  //   }
  // };


  const handleAddedProduct = () => {
    if (!selectedProductSize || !selectedProductColor) {
      setError("Please select a size and color");
      return;
    }
    setError(null)
    function generateRandomItemId() {
      return Math.floor(Math.random() * 100000); // Adjust the range as needed
    }

    const product = {
      item_id: generateRandomItemId(),
          title: productDetails.title,
          description: productDetails.description,
          price: +productDetails.price,
          category_id: productDetails.category.category_id,
          size: selectedProductSize,
          color: selectedProductColor,
          image: productDetails.images[0],
          quantity: quantity,
          totalPrice: productDetails.price * quantity,
          product_id: +product_id,
    }
    if (!cartItems.length > 1) {
      updatecartItems([product])
    } else { updatecartItems([...cartItems, product]) }
  }
  return (
    <div className="col-md product-page">
      <div className="product">
        <div className="left">
          {isMobile ? (
            <Carousel images={productDetails.images} />
          ) : (
            productDetails.images.map((image, index) => (
              <img
                key={index}
                src={image.filename}
                alt={`Product Image ${index + 1}`}
                style={{ width: '100%' }}
              />
            ))
          )}
        </div>
        <div className="right">
          <h1>{productDetails.title}</h1>
          <h3>{productDetails.description}</h3>
          <h2>{productDetails.price} dh</h2>
          {error && <h4 style={{ color: 'red' }}>{error}</h4>}

          <select
            name="sizes"
            id="sizes"
            required
            value={selectedProductSize}
            onChange={handleSizeChange}
          >
            {productDetails.sizes.sort().map((size, index) => (
              <option key={index} value={size}>
                {size}
              </option>
            ))}
          </select>

          <div style={{ display: 'flex', gap: '20px' }}>
            {isMobile ? (
              productDetails.colors.map((color, index) => (
                <div
                  key={index}
                  className="color-box"
                  style={{
                    backgroundColor: color,
                    width: '30px',
                    position: 'relative',
                    boxShadow:
                      selectedProductColor === productDetails.colors[index]
                        ? `0 0 0 2px white, 0 0 0 3px ${color}`
                        : 'none',
                  }}
                  onClick={() => handleColorChange(index, color)}
                ></div>
              ))
            ) : (
              productDetails.colors.map((color, index) => (
                <div
                  key={index}
                  className="color-box"
                  style={{
                    backgroundColor: color,
                    width: '30px',
                    height: '30px',
                    position: 'relative',
                    boxShadow:
                      selectedProductColor === productDetails.colors[index]
                        ? `0 0 0 2px white, 0 0 0 3px ${color}`
                        : 'none',
                  }}
                  onClick={() => handleColorChange(index, color)}
                ></div>
              ))
            )}

            <div
              style={{
                display: 'flex',
                width: '100px',
                justifyContent: 'space-between',
                border: '1px solid black',
              }}
            >
              <button onClick={() => handleQuantityChange(-1)} style={{ padding: '5px 10px', borderRight: '1px solid black' }}>-</button>
              <span style={{ padding: '5px 10px' }}>{quantity}</span>
              <button onClick={() => handleQuantityChange(1)} style={{ padding: '5px 10px', borderLeft: '1px solid black' }}>+</button>
            </div>
          </div>

          <Addtocart btnClass={cartItems.length <= 1 ? 'mainBtn' : 'loading'} click={handleAddedProduct} />

          <div>
            <Accordion title="Details, care & fit">
              <p>Properly caring for your shoes is essential to maintain their longevity and style. Here are some quick tips:</p>
              <ul>
                <li><strong>Clean Regularly:</strong> Wipe off dirt and stains with a damp cloth or appropriate cleaner.</li>
                <li><strong>Storage:</strong> Store in a cool, dry place, away from sunlight, using shoe trees to maintain shape.</li>
                <li><strong>Rotate:</strong> Avoid daily wear, switch between pairs to allow shoes to breathe.</li>
                <li><strong>Protection:</strong> Use waterproof spray for rainy days and suitable products for the shoe material.</li>
                <li><strong>Maintenance:</strong> Promptly address minor repairs and follow care instructions provided by the manufacturer.</li>
              </ul>

              <p>By following these steps, you can ensure your shoes remain comfortable and stylish for an extended period.</p>

            </Accordion>
            <Accordion title="Delivery & return">
              <p>
                At SKYZOO, we take great pride in ensuring a seamless shopping experience for our valued customers. Our commitment to your satisfaction extends to our delivery and returns process. When you order a pair of shoes from our website, you can expect timely and secure delivery to your doorstep. We work with trusted courier services to ensure that your purchase arrives in pristine condition and as quickly as possible.
              </p>
              <p>
                In the rare event that you're not completely satisfied with your purchase, our hassle-free returns policy is designed to make the process as simple as possible. You can return or exchange your shoes within 15 days of receiving them, provided they are in their original, unworn condition, and accompanied by the original packaging and proof of purchase. We believe in making returns stress-free, so you can shop with confidence, knowing that we're here to assist you every step of the way.
              </p>
              <p>
                Your satisfaction is our top priority, and we're dedicated to delivering not only stylish and comfortable shoes but also a customer experience that exceeds your expectations. Thank you for choosing SKYZOO, where your comfort and style are our utmost concern.
              </p>
            </Accordion>
            <Accordion title="Contact us">
              <h3>contact@skyzoshoes.ma</h3>
              <h3>+2125222-64545</h3>
            </Accordion>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Vous pouvez aimer</h3>
        <Link to={`/category/${productDetails.category.category_name}`} >See more</Link>
      </div>
      <div className="you-may-like">
        {productDetails.similarProducts
          .slice(0, isMobile ? 3 : undefined)
          .map((product) => (
            <Card key={product.product_id} product={product} designclass="home-card" />
          ))}
      </div>
    </div>
  );
}

export default Product;
