import React from 'react'
import './copyright.css'
function Copyright() {
  return (
    <div className='col-md copyright'>
      <h4>Copyright © 2023 Skyzo Shoes SARL. All Rights Reserved</h4>
      <h4>Privacy Policy</h4>

    </div>
  )
}

export default Copyright
