import React, { useState, useEffect } from 'react';
import './featured.css';
import productsData from "./products.json";
import Card from './card';
import { Menu } from './featuredMenu';
import { Link } from 'react-router-dom';
import axiosClient from '../pages/productpage/axios_client';


let x = 8;

function Featured() {
  const [products, setproducts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [results, setResults] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    fetchProductFromServer();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
     x = isMobile ? 9 : 8;
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial state

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchProductFromServer = async () => {
    try {
      const response = await axiosClient.get("/category/New Arrivals ");
      setproducts(response.data.products);
      setCategoryName(response.data.category.category_name);
      setCategoryDescription(response.data.category.category_description);
      setResults(response.data.products.length)
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='col-md featured'>
      <div className='featured-header'>
      <h2>New Arrivals</h2>
      <Link to='/category/New Arrivals'>See more</Link>
      </div>
      <div className='car-container'>
        {products.slice(0,x).map((product) => (
          <Card key={product.product_id} product={product} designclass='home-card' />
        ))}
      </div>
    </div>
  );
}

export default Featured;



