import React from 'react'

function Banner() {
  return (
    <div className='banner'>
      <div className='col-md'>
        <h3>
          Free Delivery for Orders Over 1000DH </h3>
      </div>
    </div>
  )
}

export default Banner
